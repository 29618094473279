import { gql } from "@apollo/client"

export const customerDetailsUpdateMutation = gql`
  mutation CustomerUpdate($id: ID!, $input: CustomerInput!) {
    customerUpdate(id: $id, input: $input) {
      errors {
        field
        message
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`

export const customerPhoneNumberUpdateMutation = gql`
  mutation ChangePhoneNumber($newPhone: String!, $otp: String) {
    changePhoneNumber(newPhone: $newPhone, otp: $otp) {
      user {
        id
        phone
        phoneVerified
      }
      errors {
        field
        message
      }
    }
  }
`

export const loggedUserUpdateMutation = gql`
  mutation loggedUserUpdate($input: CustomerInput!) {
    loggedUserUpdate(input: $input) {
      user {
        weight {
          unit
          value
        }
        height {
          unit
          value
        }
        gender
        dateOfBirth
      }
      errors {
        field
        message
      }
    }
  }
`

export const customerVerifyPhoneNumberMutation = gql`
  mutation VerifyPhoneNumber($otp: String!) {
    verifyPhone(otp: $otp) {
      user {
        id
        phone
        phoneVerified
      }
      errors {
        field
        message
      }
    }
  }
`

export const customerEmailUpdateMutation = gql`
  mutation ChangeEmail($newEmail: String!) {
    changeEmail(newEmail: $newEmail) {
      token
      user {
        id
        email
      }
      errors {
        field
        message
      }
    }
  }
`

export const customerVerifyEmailMutation = gql`
  mutation VerifyEmail($otp: String!) {
    verifyEmail(otp: $otp) {
      user {
        id
        email
        emailVerified
      }
      errors {
        field
        message
      }
    }
  }
`

export const changePasswordMutation = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      errors {
        field
        message
      }
    }
  }
`

export const setPasswordMutation = gql`
  mutation SetPassword($newPassword: String!, $otp: String!) {
    setPassword(newPassword: $newPassword, otp: $otp) {
      user {
        id
        passwordPresent
      }
      errors {
        field
        message
      }
    }
  }
`

import styled from "styled-components"

export const FCWrapper = styled.div<{ bottom: boolean }>`
  border-radius: 34px 8px 34px 34px;
  bottom: ${(p) => (p.bottom ? "94px" : "21px")};
  right: 20px;
  box-shadow: 0 5px 4px 0 rgb(0 0 0 / 26%);
  transition: bottom 1s ease-in-out;
  display: flex;
  padding: 2px;
  overflow: unset !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  z-index: 2147483601;
  height: 60px;
  min-height: 60px !important;
  width: 60px;
  background-color: #ffd400 !important;
  color: #ffffff !important;
  border-color: transparent #fadd00 transparent transparent;
  svg path {
    fill: #fff !important;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: conic-gradient(#0000 20%, #fff);
    mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
    animation: s3 1s infinite linear;
  }
  @keyframes s3 {
    to {
      transform: rotate(1turn);
    }
  }
`

import {
  ApolloClient,
  makeVar,
  NormalizedCacheObject,
  useReactiveVar,
} from "@apollo/client"
import { userGlobalState } from "@app/globalStates/User/userGlobalState"
import { initializeApollo } from "@temp/@next/staticProps/apolloClient"
import { User } from "@temp/@next/globalStates/User/types/User"
import { useToggle } from "@temp/@next/hooks/useToggle"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { thirdTick } from "@temp/localStates/documentComplete"
import { getMedia } from "@temp/localStates/media"
import { customerDetailsUpdateMutation } from "@temp/views/Account/components/userdetails/queries"
import { CustomerUpdate } from "@temp/views/Account/components/userdetails/types/CustomerUpdate"
import includes from "lodash/includes"
import { useRouter } from "next/router"
import Script from "next/script"
import ChatIcon from "public/images/components/freschat-interior.svg"
import React, { useEffect } from "react"

import * as S from "./style"

declare global {
  interface Window {
    fcWidget: any
  }
}

// change bottom position of fc icon on these pages
const changeBottomPages = [
  "/product/[name]/[id]",
  "/search",
  "/brand/[slug]/[id]",
  "/collection/[slug]/[id]",
  "/category/[slug]/[id]",
  "/combo-product/[name]/[id]",
]

// hide fc icon on these pages
const hiddenPages = ["payments/process-payment", "/cart"]

interface FreshChatInitProps {
  token: string
  host: string
  externalId?: string
  restoreId?: string
  onInit?: () => void
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  phoneCountryCode?: string
}

enum FreshChatKeys {
  FRESH_CHAT_SDK = "freshchat-js-sdk",
  HOST = "https://wchat.freshchat.com",
  FRESH_CHAT_SCRIPT_URL = "https://wchat.freshchat.com/js/widget.js",
  FRESH_CHAT_TOKEN = "48ecb720-024d-47bd-a2c6-f9bbd5fcd14d",
}

// util
const isBrowser = typeof window !== "undefined"

// global states
export const freshChatInitialized = makeVar(false)
export const loadFCScript = makeVar(false) // to check if freshchat script has loaded in the dom
export const canUseFCWidget = makeVar(false) // to check if window.fcWidget is available

// helper functions for freshchat

export const handleUserLoggedIn = (user: User) => {
  if (window.fcWidget && window.fcWidget.user && user) {
    window.fcWidget.user.setProperties({
      restoreId: user.freshchatId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      phoneCountryCode: "+91",
    })
  }
}

export const initializeFreshChat = () => {
  const { user } = userGlobalState()
  if (freshChatInitialized() && !!user) {
    // user is logged in
    handleUserLoggedIn(user)
  } else {
    freshChatInitialized(true)
  }
}

const updateFreshChatId = async (updatedFreshChatId: string) => {
  const { user } = userGlobalState()
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo()
  if (user && user.freshchatId !== updatedFreshChatId) {
    return apolloClient.mutate<CustomerUpdate>({
      mutation: customerDetailsUpdateMutation,
      variables: {
        id: user.id,
        input: {
          freshchatId: updatedFreshChatId,
        },
      },
    })
  }
  return Promise.resolve()
}

const handleFreshChatUserCreatedResponse = async ({
  status,
  data,
}: {
  status: number
  data: { restoreId: string }
}) => {
  const { restoreId } = data || {}
  if (status === 200 && restoreId) {
    return updateFreshChatId(restoreId)
  }
  return Promise.resolve()
}

const addFreshChatListeners = () => {
  canUseFCWidget(true)
  if (isBrowser) {
    window.fcWidget.on("user:created", handleFreshChatUserCreatedResponse)
  }
}

const handleFreshChatScriptLoaded = () => {
  const { user } = userGlobalState()
  let initProps: FreshChatInitProps = {
    token: FreshChatKeys.FRESH_CHAT_TOKEN,
    host: FreshChatKeys.HOST,
  }
  if (user) {
    initProps = {
      ...initProps,
      externalId: user.id,
      restoreId: user.freshchatId ? user.freshchatId : undefined,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email || undefined,
      phone: user.phone ? user.phone : undefined,
      phoneCountryCode: "+91",
    }
  }

  if (isBrowser && window.fcWidget) {
    window.fcWidget.init(initProps)
    addFreshChatListeners()
  }
}

// Component
export const FreshChat: React.FC = () => {
  const router = useRouter()
  const matches: GlobalMediaQueryResultInterface = getMedia()

  const isFreshChatInitialized = useReactiveVar(freshChatInitialized)
  const loadScript = useReactiveVar(loadFCScript)
  const canUseFc = useReactiveVar(canUseFCWidget)
  const tick = useReactiveVar(thirdTick)
  const [openFC, toggleOpenFC] = useToggle(false) // to toggle open and close fc widget container
  const [showFC, toggleShowFC] = useToggle(true) // to toggle display of fc chat icon
  const [loading, toggleLoading] = useToggle(false) // to toggle loading of fc script when clicked
  const [bottom, toggleBottom] = useToggle(
    includes(changeBottomPages, router.pathname) && matches.small
  ) // to toggle bottom style of fc icon

  // side effects
  useEffect(() => {
    // load the freshchat script after third tick
    if (tick && isFreshChatInitialized && !loadScript) {
      loadFCScript(true)

      // unmount the freshchat facade after 5 seconds.
      // (assuming the freshchat script will be loaded in 5 sec)
      setTimeout(() => toggleShowFC(false), 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tick])

  useEffect(() => {
    if (canUseFc && openFC) {
      window.fcWidget.open()
    }
  }, [openFC, canUseFc])

  // side effect for fc widget open and close
  useEffect(() => {
    if (canUseFc) {
      window.fcWidget.on("widget:closed", () => {
        toggleLoading(false)
      })
      window.fcWidget.on("widget:opened", () => {
        toggleShowFC(false)
        toggleOpenFC(false)
        if (loading && !tick) {
          toggleLoading(false)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUseFc])

  // to change fc icon style depending on pages
  useEffect(() => {
    if (matches.small) {
      if (includes(changeBottomPages, router.pathname)) {
        toggleBottom(true)
        if (canUseFc) {
          document.querySelector("#fc_frame")!.classList.add("bottom-change")
        }
      } else {
        toggleBottom(false)
        if (canUseFc) {
          document.querySelector("#fc_frame")!.classList.remove("bottom-change")
        }
      }

      if (includes(hiddenPages, router.pathname)) {
        toggleShowFC(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, canUseFc])

  return (
    <>
      {showFC && (
        <S.FCWrapper
          id="fc-icon"
          bottom={bottom}
          onClick={() => {
            if (!loading && !canUseFc && !tick) {
              toggleLoading()
            }
            if (!loadScript) {
              loadFCScript(true)
            }
            if (!openFC) {
              toggleOpenFC()
            }
          }}
        >
          {loading && !tick ? <span className="spinner" /> : <ChatIcon />}
        </S.FCWrapper>
      )}
      {loadScript && (
        <Script
          src={FreshChatKeys.FRESH_CHAT_SCRIPT_URL}
          id={FreshChatKeys.FRESH_CHAT_SDK}
          strategy="lazyOnload"
          onLoad={() => {
            handleFreshChatScriptLoaded()
          }}
        />
      )}
    </>
  )
}

import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { UserGlobal, userGlobalState } from "@app/globalStates/User/userGlobalState"
import { AccessTokenInput, AuthProvider } from "@temp/../types/globalTypes"
import { initializeApollo } from "@temp/@next/staticProps/apolloClient"
import {
  handleUserLoggedIn,
  initializeFreshChat,
} from "@temp/components/FreshChatProvider/FreshChat"
import { removeAuthToken, setAuthToken } from "@temp/core/auth"
import { getDBIdFromGraphqlId, trackEvent } from "@temp/core/utils"
import { signOut, useSession } from "next-auth/client"
import React, { useEffect } from "react"
import { AlertManager, useAlert } from "react-alert"
import { useCookies } from "react-cookie"
import { tokenAuthMutation, tokenVerificationMutation } from "./queries"
import { TokenAuth_tokenCreate_user } from "./types/TokenAuth"

// helper functions
const setTrackingUserId = async (user: TokenAuth_tokenCreate_user | null, name: string, method: string | null, errors: boolean | null = false, verifyMethod: string | null = "password") => {
  let status = null
  const id = user?.id
  let userName = null
  if (user?.inferredFirstName && user?.inferredFirstName?.length > 0 && user?.inferredLastName && user?.inferredLastName?.length > 0) {
    userName = user?.inferredFirstName + " " + user?.inferredLastName
  }
  const email = user?.inferredEmail
  const phone = user?.inferredPhone
  if (errors != null) {
    if (errors === true) {
      status = "failed"
    }
    else {
      status = "success"
    }
  }
  let eventValue = null
  if (id && method && name === "login") {
    eventValue = {
      userId: getDBIdFromGraphqlId(id),
      userName,
      email,
      phone,
      method: method.toLowerCase(),  // for google and facebook
      verifyMethod,
      status
    }
  }
  else if (name === "logout") {
    eventValue = {
      userId: null,
      userName: null,
      email: null,
      phone: null,
    }
  }
  else if (id && name === "sign_up") {
    let method2 = null;
    if (method === "phone" && verifyMethod === "password") {
      method2 = "email"
    }
    eventValue = {
      userId: getDBIdFromGraphqlId(id),
      userName,
      email,
      phone,
      method: method?.toLowerCase(),  // for google and facebook
      method2,
      verifyMethod,
      status
    }
  }
  trackEvent({
    eventName: name,
    eventValue: eventValue,
    customEvent: false,
    sendToDataLayer: true,
    sendToUA: true,
  })
}

const handleSentryScope = async (id) => {
  const Sentry = await import("@sentry/nextjs")
  Sentry.configureScope((scope) => {
    scope.setUser({ id })
  })
}

export const triggerFailedLoginEvent = (method: string | null, verifyMethod: string = "password") => {
  setTrackingUserId(null, "login", method, true, verifyMethod)
}

export const login = (
  token: string,
  user: TokenAuth_tokenCreate_user,
  alert: AlertManager,
  method: string,
  verifyMethod: string | null = "password",
  isNewuser: boolean = false
): void => {
  alert.show(
    {
      title: "Logging in...",
    },
    { type: "success" }
  )
  // set token in local Storage
  setAuthToken(token)
  // set global state here
  userGlobalState({
    ...userGlobalState(),
    token,
    user,
    loading: false,
  })
  handleUserLoggedIn(user) // passing user to freshchat
  try {
    Android.subscribeNotification(token)
    // eslint-disable-next-line no-empty
  } catch { }
  // set ReactGA user id and sentry user scope
  if (process.env.NEXT_PUBLIC_STOREFRONT_ENV === "PRODUCTION" || process.env.NEXT_PUBLIC_TRACK_EVENTS === "TRUE") {
    const eventName = isNewuser ? "sign_up" : "login"
    setTrackingUserId(user, eventName, method, false, verifyMethod)
    handleSentryScope(user.id)
  }

  // show alert after login
  alert.show(
    {
      title: "You are now logged in",
    },
    { type: "success" }
  )
}

export const logout = (alert: AlertManager, user: TokenAuth_tokenCreate_user): void => {
  // remove token from local storage
  removeAuthToken()
  // show alert after logout
  alert.show(
    {
      title: "You are now logged out",
    },
    { type: "success" }
  )
  if (process.env.NEXT_PUBLIC_STOREFRONT_ENV === "PRODUCTION" || process.env.NEXT_PUBLIC_TRACK_EVENTS === "TRUE") {
    setTrackingUserId(user, "logout", null, null, null)
  }
  userGlobalState({ ...userGlobalState(), token: null, user: null })
}

export const updateUser = (user: TokenAuth_tokenCreate_user): void => {
  userGlobalState({ ...userGlobalState(), user })
  handleUserLoggedIn(user)
}

// update token in context and local storage
export const updateToken = (token: string): void => {
  userGlobalState({ ...userGlobalState(), token })
  localStorage.setItem("token", token)
}

const authenticate = async (token: string): Promise<void> => {
  userGlobalState({ ...userGlobalState(), loading: true })
  let state: UserGlobal = { errors: null, loading: false, token: null, user: null }
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo()
  try {
    const {
      data: {
        tokenVerify: { user },
      },
    } = await apolloClient.mutate({
      mutation: tokenVerificationMutation,
      variables: { token },
    })
    // try sending notification
    try {
      Android.subscribeNotification(token)
      // eslint-disable-next-line no-empty
    } catch { }
    state = { ...state, user, token }
  } catch ({ message }) {
    state.errors = message
  }
  // update userState here
  userGlobalState({ ...state })
  // re-initialize freshchat to update user details
  initializeFreshChat()
}

// social login using next-auth
const onSocialLogin = async (provider, value, alert, click_id, referral_code) => {
  const accessToken: AccessTokenInput = { provider, value }
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo()
  userGlobalState({ ...userGlobalState(), loading: true })
  let response;
  if (click_id && referral_code) {
    response = await apolloClient.mutate({
      mutation: tokenAuthMutation,
      variables: {
        accessToken,
        clickId: click_id,
        referralCode: referral_code,
      },
    })
  } else {
    response = await apolloClient.mutate({
      mutation: tokenAuthMutation,
      variables: {
        accessToken,
      },
    })
  }

  const {
    data: {
      tokenCreate: { token, user, errors, isNewUser },
    },
  } = response;

  if (errors && errors.length > 0) {
    alert.show(
      {
        title: errors.map((error: any) => error.message).join(", "),
      },
      { type: "error" }
    )
    userGlobalState({ ...userGlobalState(), loading: false })
    // This is to handle the case when user doesn't have email on Facebook.
    // Next-auth treats it as successful login and store session token but our backend doesn't allow such login
    // This signout is to remove that session token so that user doesn't keep getting error message on page refresh
    await signOut({ redirect: false })
  } else {
    login(token, user, alert, provider, null, isNewUser)
  }
}

// eslint-disable-next-line import/no-unused-modules
export const UserState: React.FC = () => {
  const alert = useAlert()
  const { user, token } = userGlobalState()
  const [session, loading] = useSession()
  const [cookies, setCookie] = useCookies(["click_id", "referral_code"])

  const tokenAccess = session && session.accessToken

  if (tokenAccess && loading && !token) {
    alert.show(
      {
        title: "Logging in...",
      },
      { type: "success" }
    )
  }

  useEffect(() => {
    if (user === null && !token && session && "accessToken" in session) {
      if (cookies.click_id && cookies.referral_code) {
        onSocialLogin(
          session.provider === "google" ? AuthProvider.GOOGLE : AuthProvider.FACEBOOK,
          tokenAccess,
          alert,
          cookies.click_id,
          cookies.referral_code,
        )
      } else {
        onSocialLogin(
          session.provider === "google" ? AuthProvider.GOOGLE : AuthProvider.FACEBOOK,
          tokenAccess,
          alert,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session && session.accessToken])

  useEffect(() => {
    // this will run only once during the mounting phase
    if (token) {
      authenticate(token)
    } else {
      initializeFreshChat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <></>
}
